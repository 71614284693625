import React from 'react';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import {
  PillowWarrantyHeroSection,
  PillowWarrantyIntroSection,
  PillowWarrantyContentSection,
  PillowWarrantyFooterSection,
} from '../../../components/sections';
import { CountryCode } from '../../../utils/constants';

const WoosaPillowWarranty: React.FC = () => {
  return (
    <Layout countryCode={CountryCode.SG}>
      <SEO title="The Woosa Pillow" />
      <PillowWarrantyHeroSection />
      <PillowWarrantyIntroSection />
      <PillowWarrantyContentSection />
      <PillowWarrantyFooterSection />
    </Layout>
  );
};

export default WoosaPillowWarranty;
